<template>
  <div class="Userhl_jiaoyi Userhl_rightbox">
    <h1>交易评价模板</h1>
  </div>
</template>
<script>
export default {
  components: {

  },
};
</script>
